import React, { useLayoutEffect } from "react";
import {
  Container,
  Segment,
  Grid,
  Header,
  Image,
  Button,
  List,
  Divider,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";

const NyborjarePage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Segment
        placeholder
        style={{
          height: "40em",
          backgroundImage: "url(" + process.env.PUBLIC_URL + "/hälsning.jpg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Header
          as="h1"
          content="Börja träna"
          inverted
          style={{
            fontSize: "4em",
            fontWeight: "normal",
            marginBottom: 0,
            marginTop: "3em",
          }}
        />
      </Segment>

      <Segment style={{ padding: "8em 0em" }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Är du nyfiken på att börja träna Ju-jutsu?
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                Ju-jutsu passar den som vill hålla sig i form, träna
                självförsvar och ha roligt, oavsett ålder, kön eller bakgrund.
                Alla tränar efter sina egna förutsättningar och teknikerna
                anpassas individuellt. Du behöver inte ha någon tidigare
                erfarenhet.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                Vi har träningsgrupper för alla! Från 7 år och uppåt, och nej,
                det finns ingen övre åldersgräns
              </p>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Hur börjar jag?
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                Kom till vår träning och träffa oss! Vi erbjuder två veckor "prova på" utan avgift innan du bestämmer dig
                om Ju-jutsu är något för dig.
                Har du några frågor så går det jättebra att maila oss på &nbsp;
                <a href="mailto:info@falujjk.se">info@falujjk.se</a>
              </p>

              <Header as="h3" style={{ fontSize: "2em" }}>
                Utrustning
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                I början går det utmärkt att träna i mjuka byxor med långa ben och utan
                vassa detaljer samt en t-shirt, gärna långärmad om du har. Vi tränar barfota.
                Tänk på att inte bära några smycken för din och andras säkerhet. När du bestämt dig för att
                fortsätta är det dags att skaffa en Gi (träningsdräkt) med vitt
                bälte (nybörjarnivå). Dräkten går att köpa på egen hand eller
                genom klubben.
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={6}>
              <Image
                centered
                src={process.env.PUBLIC_URL + "/försöktillkravattgrepp.jpg"}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Segment basic style={{ padding: "8em 0em" }} vertical>
          <Image
            centered
            size="large"
            src={process.env.PUBLIC_URL + "/bälten_close.jpg"}
          />
        </Segment>

        <Segment style={{ padding: "8em 0em" }} vertical>
          <Container text>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Om Falu Ju-jutsuklubb
            </Header>
              <p style={{ fontSize: "1.33em" }}>
                Falu ju-jutsuklubb är en ideell förening för den som vill träna ju-jutsu i Falun enligt stilen Ju-jutsu Kai. Föreningen bildades 2013 av en grupp erfarna ju-jutsuutövare.
              </p>
            <Button size="huge" primary as="a">
              <NavLink to="/instruktorer" style={{ color: "white" }}>
                Läs mer om föreningen
              </NavLink>
            </Button>
            <Divider style={{ margin: "3em 0em" }} />
            <Header as="h3" style={{ fontSize: "2em" }}>
              Träningsgrupper
            </Header>
            <Segment basic textAlign="left">
              <p style={{ fontSize: "1.33em" }}>
                <List>
                  <List.Item>
                    <b>Knattar.</b> Barn 7-9 år. Barnet ska ha fyllt 7 år för
                    att börja
                  </List.Item>
                  <List.Item>
                    <b>Mon.</b> Barn och ungdomar mellan 9 och 14 år.
                  </List.Item>
                  <List.Item>
                    <b>Vuxna Nybörjare / Grund.</b> Nybörjare upp till orange
                    bälte
                  </List.Item>
                  <List.Item>
                    <b>Vuxna Fortsättning / Avancerade.</b> Grönt bälte och
                    uppåt
                  </List.Item>
                </List>
              </p>
            </Segment>
            <Button size="huge" primary as="a">
              <NavLink to="/grupper" style={{ color: "white" }}>
                Läs mer om våra grupper
              </NavLink>
            </Button>
            <Divider style={{ margin: "3em 0em" }} />
            <Header as="h3" style={{ fontSize: "2em" }}>
              Träningstider
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Vi tränar tisdagar och torsdagar i Kampsportshallen på Lugnet. Vi
              erbjuder två träningstillfällen per vecka i våra Mon och
              Vuxengrupper. Knattar en gång per vecka.
            </p>
            <Button size="huge" primary as="a">
              <NavLink to="/schema" style={{ color: "white" }}>
                Läs mer om våra träningstider
              </NavLink>
            </Button>
            <Divider style={{ margin: "3em 0em" }} />
            <Header as="h3" style={{ fontSize: "2em" }}>
              Priser
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Du behöver vara medlem i föreningen och betala träningsavgift.
              Medlemsavgiften betalas en gång per år och är 300 kr.
              Träningsavgiften betalas per termin och är 500 kr för barn och 800
              kr för vuxna
            </p>
            <Button size="huge" primary as="a">
              <NavLink to="/avgifter" style={{ color: "white" }}>
                Läs mer om våra avgifter
              </NavLink>
            </Button>
            <Divider style={{ margin: "3em 0em" }} />
            <Header as="h3" style={{ fontSize: "2em" }}>
              Hur hittar jag till träningen?
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Vi tränar i kampsportshallen på Lugnet<br/>
              Ingång vid sporthallarna<br/>
              Lugnetvägen 5, 79131 FALUN
            </p>
            <br />
            <iframe
              width="425"
              height="350"
              frameborder="0"
              title="kartbild"
              marginheight="0"
              marginwidth="0"
              src="https://www.openstreetmap.org/export/embed.html?bbox=15.64908027648926%2C60.61371200098469%2C15.665516853332521%2C60.62004994333548&amp;layer=mapnik&amp;marker=60.61688112779665%2C15.657298564910889"
              style={{ border: "1px solid black", pointerEvents: "none" }}
            ></iframe>


            <Divider style={{ margin: "3em 0em" }} />
            <Header as="h3" style={{ fontSize: "2em" }}>
              Är jag för gammal att börja träna? Jag har aldrig provat kampsport
              förr
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Alla kan träna ju-jutsu! Du är aldrig för gammal för att börja
              träna, och du behöver ingen tidigare erfarenhet av kampsport. Var och
              en får träna på sina villkor, utan press på prestationer eller
              graderingar. Hur ”hårt eller mjukt” man vill träna är helt och
              hållet upp till en själv - det viktiga är att man har roligt och
              utvecklas
            </p>
            <Divider style={{ margin: "3em 0em" }} />
            <Header as="h3" style={{ fontSize: "2em" }}>
              Är jag för ung för att börja träna?
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Det kan man däremot vara. Man ska ha fyllt 7 år för att börja i vår knattegrupp
            </p>
            <Divider style={{ margin: "3em 0em" }} />
            <Header as="h3" style={{ fontSize: "2em" }}>
              Vilken Ju-jutsu stil tränar man?
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Svenska Ju-jutsuförbundet är en organisation för stilen Ju-jutsu
              Kai med cirka 60 medlemsklubbar spridda över hela landet. Ju-jutsu
              är ett samlingsnamn för japansk kampkonst och självförsvar utan
              vapen och ju-jutsu kan översättas till mjuk eller flexibel teknik.
              Ju-jutsu Kai erbjuder en bra, modern och omväxlande
              självförsvarsträning med rötter i traditionell kampkonst.
              Förbundets vision är: ”Ju-jutsu Kai ska vara det självklara valet
              för de som vill träna kampsport med självförsvarsinriktning”
            </p>
            <Button
              size="huge"
              primary
              as="a"
              href="https://www.ju-jutsu.se"
              target="_"
            >
              Läs mer på förbundets hemsida
            </Button>
          </Container>
        </Segment>

        <Segment
          placeholder
          style={{
            height: "40em",
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/knatte3.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Header
            as="h1"
            content="Rörlighet - Smidighet - Koordination"
            inverted
            style={{
              fontSize: "4em",
              fontWeight: "normal",
              marginBottom: 0,
              marginTop: "3em",
            }}
          />
        </Segment>


      </Segment>
    </>
  );
};

export default NyborjarePage;
