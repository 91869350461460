/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react'
import { HashRouter, NavLink, Route, Routes, useLocation } from 'react-router-dom'
import { InView } from 'react-intersection-observer'
//import PropTypes from 'prop-types'

import { createMedia } from '@artsy/fresnel'
import {
  Container,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'

import BottomMenu from './pages/BottomMenu'
import StartPage from './pages/StartPage'
import InstruktorerPage from './pages/InstruktorerPage.js'
import AvgifterPage from './pages/AvgifterPage.js'
import NyborjarePage from './pages/NyborjarePage.js'
import SchemaPage from './pages/SchemaPage.js'
import GrupperPage from './pages/GrupperPage.js'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})


/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */

const DesktopContainer = ({ children }) => {

  const [fixed, setFixed] = useState();
  let location = useLocation()

  const toggleFixedMenu = (inView) => setFixed(!inView)

  return (
    <Media greaterThan='mobile'>
      <InView onChange={toggleFixedMenu}>
        <Segment
          textAlign='center'
          style={{ padding: '1em 0em' }}
          vertical
        >
          <Menu
            fixed={fixed ? 'top' : null}

            secondary={!fixed}
            size='large'
          >
            <Container>
              <Image src={process.env.PUBLIC_URL + "/falu-jjk.jpg"} size='small' spaced='right' />
              <Menu.Item header><Header as='h1' textAlign='left'><Header.Content>Falu Ju-jutsuklubb<Header.Subheader>Självförsvar - Kampsport - Bra träning</Header.Subheader></Header.Content></Header></Menu.Item>

              <Menu.Item active={location.pathname === "/"}> <NavLink to="/">Hem</NavLink></Menu.Item>
              <Menu.Item active={location.pathname === "/nyborjare"}><NavLink to="/nyborjare">Börja träna</NavLink></Menu.Item>
              <Menu.Item active={location.pathname === "/grupper"}><NavLink to="/grupper">Våra Grupper</NavLink></Menu.Item>
              <Menu.Item active={location.pathname === "/schema"}><NavLink to="/schema">Schema</NavLink></Menu.Item>
              <Menu.Item active={location.pathname === "/avgifter"}><NavLink to="/avgifter">Avgifter</NavLink></Menu.Item>
              <Menu.Item active={location.pathname === "/instruktorer"}><NavLink to="/instruktorer">Klubben</NavLink></Menu.Item>

              <Menu.Item position='right'>
                <a href="https://www.facebook.com/groups/falujjk" target='_'><Icon name='facebook' size='big' color='blue' link></Icon></a>
                <a href="https://www.instagram.com/falujjk/" target="_"><Icon name='instagram' size='big' color='purple' link></Icon></a>
              </Menu.Item>
            </Container>
          </Menu>
        </Segment>
      </InView>

      {children}
    </Media>

  );
};

const MobileContainer = ({ children }) => {
  const [sidebarOpened, setSidebarOpened] = useState();
  let location = useLocation()

  const handleSidebarHide = () => setSidebarOpened(false);
  const handleToggle = () => setSidebarOpened(true);

  return (
    <Media as={Sidebar.Pushable} at='mobile'>
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation='overlay'
          inverted
          onHide={handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item active={location.pathname === "/"} onClick={handleSidebarHide}> <NavLink to="/">Hem</NavLink></Menu.Item>
          <Menu.Item active={location.pathname === "/nyborjare"} onClick={handleSidebarHide}><NavLink to="/nyborjare">Börja träna</NavLink></Menu.Item>
          <Menu.Item active={location.pathname === "/grupper"} onClick={handleSidebarHide}><NavLink to="/grupper">Våra Grupper</NavLink></Menu.Item>
          <Menu.Item active={location.pathname === "/schema"} onClick={handleSidebarHide}><NavLink to="/schema">Schema</NavLink></Menu.Item>
          <Menu.Item active={location.pathname === "/avgifter"} onClick={handleSidebarHide}><NavLink to="/avgifter">Avgifter</NavLink></Menu.Item>
          <Menu.Item active={location.pathname === "/instruktorer"} onClick={handleSidebarHide}><NavLink to="/instruktorer">Klubben</NavLink></Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment

            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical
          >
            <Container>

              <Menu pointing secondary size='large'>
                <Menu.Item onClick={handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
              </Menu>
            </Container>

            <Container>
              <Image src={process.env.PUBLIC_URL + "/falu-jjk.jpg"} size='small' spaced='right' />
              <Header as='h1' ><Header.Content>Falu Ju-jutsuklubb<Header.Subheader>Självförsvar - Kampsport - Bra träning</Header.Subheader></Header.Content></Header>
            </Container>
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media >

  );
};


const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

//ResponsiveContainer.propTypes = {
//  children: PropTypes.node,
//}

const HomepageLayout = () => {
  return (
    <>
      <HashRouter >
        <ResponsiveContainer>
          <Routes>
            <Route exact path="/" element={<StartPage />} />
            <Route path="/instruktorer" element={<InstruktorerPage />} />
            <Route path="/avgifter" element={<AvgifterPage />} />
            <Route path="/grupper" element={<GrupperPage />} />
            <Route path="/nyborjare" element={<NyborjarePage />} />
            <Route path="/schema" element={<SchemaPage />} />
          </Routes>

          <BottomMenu />
        </ResponsiveContainer>
      </HashRouter>
    </>
  );
}

export default HomepageLayout 