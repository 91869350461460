import React, { useState, useEffect, useLayoutEffect } from "react";
import { Card, Image, Container, Header, Divider } from "semantic-ui-react";
import Beltimage from "../components/Beltimage";

const InstruktorerPage = () => {
  const [instructors, setInstructors] = useState();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/data/instructors.json")
      .then((response) => response.json())
      //.then(json => json.map( (x) => { return x.name }))
      .then((data) => {
        setInstructors(data);
      });
  }, []);

  return (
    <>
      <Container textAlign="left">
        <Header as="h1" textAlign="center">
          Om Klubben
        </Header>

        <p style={{ fontSize: "1.33em" }}>
          Falu ju-jutsuklubb är en ideell förening för den som vill träna
          ju-jutsu i Falun enligt stilen Ju-jutsu Kai. Föreningen bildades 2013
          av en grupp erfarna ju-jutsuutövare, som tidigare utövat Ju-jutsu Kai 
          i andra klubbar.
        </p>
        <p style={{ fontSize: "1.33em" }}>
          Klubben har utvecklats genom åren och utökat verksamheten i våra
          grupper samt satsat på att utbilda fler instruktörer. 2023 kom en
          första milstolpe då vi hade den första dan-graderingen i klubben. Vi
          följde upp året efter med ytterligare fyra dan-graderingar!
        </p>
        <p style={{ fontSize: "1.33em" }}>
          Falu Ju-jutsuklubb är medlem i Svenska Ju-jutsuförbundet, sveriges
          största Ju-jutsustil. Falu Ju-jutsuklubb är också ansluten till
          Riksidrottsförbundet via Svenska Budo och Kampsportsförbundet och är
          därmed en del av idrottsrörelsen i Sverige.
        </p>
        <p style={{ fontSize: "1.33em" }}>
          Våra instruktörer är utbildade och certifierade genom Svenska
          Ju-jutsuförbundets licensgivande kurser. Vi strävar också efter att
          kontinuerligt delta på kurser och läger över hela landet för att hela
          tiden hålla oss uppdaterade och inspirerade för att vidareutvecklas i
          vårt instruerande och ge hög kvalitet på Ju-jutsu till alla medlemmar
        </p>
        <p style={{ fontSize: "1.33em" }}>
          Blickar vi framåt så vill vi fortsätta utvecklas som förening och
          hitta fler som upptäcker det roliga i att träna Ju-jutsu. Vi är
          definitivt med på att hjälpa till med förbundets vision - ”Ju-jutsu
          Kai ska vara det självklara valet för de som vill träna kampsport med
          självförsvarsinriktning”
        </p>
      </Container>
      <Divider style={{ margin: "3em 0em" }} />

        <Header as="h1" textAlign="center">
          Våra instruktörer
        </Header>

        <Container textAlign="left">

        <Card.Group itemsPerRow={4} stackable>
          {instructors &&
            instructors.map((instr) => (
              <Card key={instr.key} centered>
                <Image centered size="small"
                  src={process.env.PUBLIC_URL + "/" + instr.imagepath}
                  
                />

                <Card.Content>
                  <Card.Header>{instr.name}</Card.Header>
                  <Card.Meta>
                    <span className="date">{instr.gradename}</span>
                    <br />
                    <span className="date">{instr.license}</span>
                  </Card.Meta>
                  <Card.Description>{instr.description}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Beltimage type={instr.gradecode}> </Beltimage>
                </Card.Content>
              </Card>
            ))}
          ;
        </Card.Group>
        </Container>
    </>
  );
};

export default InstruktorerPage;
