import React, { useLayoutEffect } from "react";
import {
  Segment,
  Grid,
  Header,
  Button,
  Image,
  Icon,
} from "semantic-ui-react";

import { NavLink } from "react-router-dom";

const StartPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Segment
        placeholder
        style={{
          height: "40em",
          backgroundImage: "url(" + process.env.PUBLIC_URL + "/main01.jpg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Header
          as="h1"
          content="Självförsvar - Kampsport - Bra träning för hela kroppen"
          inverted
          style={{
            fontSize: "4em",
            fontWeight: "normal",
            marginBottom: 0,
            marginTop: "3em",
          }}
        />
      </Segment>

      <Segment style={{ padding: "8em 0em" }} vertical>
        <Grid container stackable verticalAlign="middle" columns={2}>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Börja träna Ju-jutsu?
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                Ju-jutsu passar den som vill hålla sig <b>i form</b>, träna{" "}
                <b>självförsvar</b> och ha <b>roligt</b>, oavsett ålder
                <sup>*</sup>, kön eller bakgrund. Alla tränar efter sina egna
                förutsättningar och teknikerna anpassas individuellt
                <br />
                <span style={{ fontSize: "0.8em" }}>(* från 7 år)</span>
              </p>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Hur gör jag?
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                Kom till vår träning och träffa oss! Vi erbjuder två veckor
                "prova på" utan avgift innan du bestämmer dig om Ju-jutsu är
                något för dig. Har du några frågor så går det jättebra att maila
                oss på &nbsp;
                <a href="mailto:info@falujjk.se">info@falujjk.se</a>
              </p>
              <Button size="huge" primary as="a">
                <NavLink to="/nyborjare" style={{ color: "white" }}>
                  Läs mer om hur du kan börja träna
                </NavLink>
              </Button>
            </Grid.Column>
            <Grid.Column width={6}>
              <div class="ui segment basic">
                <Image
                  centered
                  rounded
                  src={process.env.PUBLIC_URL + "/inshot.gif"}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: "0em" }} vertical>
        <Grid celled="internally" columns="equal" stackable>
          <Grid.Row textAlign="center">
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Vill du beställa profilkläder?
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                <a href="https://falu-jujutsuklubb.myspreadshop.se/">
                  Besök butiken här
                </a>{" "}
                för att beställa profilkläder. <br />
                Behöver du hjälp att beställa träningsdräkt, Gi? Prata med din
                instruktör
              </p>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Följ oss på sociala medier!
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                Där publiceras löpande information och nyheter om det som händer
                i föreningen.
                <br />
                <a href="https://www.facebook.com/groups/falujjk" target="_">
                  <Icon name="facebook" size="big" color="blue" link></Icon>
                </a>
                <a href="https://www.instagram.com/falujjk/" target="_">
                  <Icon name="instagram" size="big" color="purple" link></Icon>
                </a>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: "0em 0em" }} vertical>
        <Grid
          container
          stackable
          verticalAlign="middle"
          style={{ padding: "8em 0em" }}
        >
          <Grid.Row>
            <Grid.Column width={4}>
              <div class="ui segment basic">
                <Image
                  centered
                  rounded
                  size="small"
                  src={process.env.PUBLIC_URL + "/svjjf.jpg"}
                />
              </div>
            </Grid.Column>

            <Grid.Column floated="right" width={10}>
              <p style={{ fontSize: "1.33em" }}>
                Falu Ju-jutsuklubb är medlem i <b>Svenska Ju-jutsuförbundet</b>,
                som är organisationen för stilen{" "}
                <b>
                  <i>Ju-jutsu Kai</i>
                </b>
                . Stilen har cirka 5.000 medlemmar runt om i Sverige och ca 60
                anslutna föreningar, vilket gör det till sveriges största
                Ju-jutsustil
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={10}>
              <p style={{ fontSize: "1.33em" }}>
                Falu Ju-jutsuklubb är ansluten till
                <b>
                  <i> Riksidrottsförbundet </i>
                </b>
                via
                <b>
                  <i> Svenska Budo och Kampsportsförbundet </i>
                </b>
                och är därmed en del av idrottsrörelsen i Sverige
              </p>
            </Grid.Column>

            <Grid.Column floated="right" width={4}>
              <div class="ui segment basic">
                <Image
                  centered
                  rounded
                  src={process.env.PUBLIC_URL + "/sbk_logotype_rund.png"}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment basic>
        <Header as="h3" style={{ fontSize: "2em" }}>
          Vi tränar i Kampsportshallen på Lugnet
        </Header>
        <iframe
          width="100%"
          height="350"
          frameborder="0"
          title="kartbild"
          marginheight="0"
          marginwidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=15.64908027648926%2C60.61371200098469%2C15.665516853332521%2C60.62004994333548&amp;layer=mapnik&amp;marker=60.61688112779665%2C15.657298564910889"
          style={{ border: "1px solid black", pointerEvents: "none" }}
        ></iframe>
      </Segment>
    </>
  );
};

export default StartPage;
