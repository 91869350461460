import React from "react";
import { Segment, Container, Grid, Header, List, Image } from "semantic-ui-react";

const BottomMenu = () => {

    return (
        <Segment inverted vertical style={{ padding: '3em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Image size='small' src={process.env.PUBLIC_URL + '/logo_vittext.png'} />
              
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted>
                Falu - Jujutsuklubb
              </Header>
              <p>
              Falu Ju-jutsuklubb är en ideel idrottsförening som huvudsakligen utövar Ju-jutsu Kai. 
              Vi bedriver verksamhet för knattar, barn, ungdomar och vuxna
              </p>
            </Grid.Column>
            <Grid.Column width={3} textAlign="left">
              <Header inverted as='h4' content='Kontakt' />
              <List link inverted>
                <List.Item><b>Epost</b></List.Item>
                <List.Item><i><a href="mailto:info@falujjk.se">info@falujjk.se</a></i></List.Item>
                <List.Item>Träningslokal</List.Item>
                <List.Item>Lugnetvägen 5 (Sporthallarna), FALUN </List.Item>
              </List>
            </Grid.Column>
            
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
    );
};

export default BottomMenu;