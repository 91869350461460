import React, {useLayoutEffect} from "react";
import { Card, Container, Header, Segment, Table } from "semantic-ui-react";

const AvgifterPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

    return (
            <Container>
                <Header as='h1'>Avgifter</Header>
                <Segment textAlign="left">
                    <p style={{ fontSize: "1.33em" }}>Vi erbjuder alltid två veckors prova-på period utan avgift</p>
                </Segment>


                <Card.Group itemsPerRow={3} stackable>
                    <Card>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell colspan={2}><Header as='h3' textAlign="center">Medlems- och träningsavgifter</Header></Table.HeaderCell>
                            </Table.Header>
                            <Table.Row>
                                <Table.Cell>Medlemsavgift per år<br /><i>(Vårt verksamhetsår följer skolåret, så medlemsavgift betalas på hösten)</i></Table.Cell>
                                <Table.Cell singleLine><b>300 kr</b></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Träningsavgift Vuxna <br /><i>(Betalas per termin)</i></Table.Cell>
                                <Table.Cell singleLine><b>800 kr</b></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Träningsavgift Barn <br /><i>(Betalas per termin)</i></Table.Cell>
                                <Table.Cell singleLine><b>500 kr</b></Table.Cell>
                            </Table.Row>
                        </Table>

                    </Card>
                    <Card>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell colspan={2}><Header as='h3' textAlign="center">Graderingsavgift</Header></Table.HeaderCell>
                            </Table.Header>
                            <Table.Row>
                                <Table.Cell>Graderingsavgift Knatte<br /><i>(Inklusive diplom och rött band)</i></Table.Cell>
                                <Table.Cell singleLine><b>100 kr</b></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Graderingsavgift Mon<br /><i>(inklusive bälte, diplom och Monpass)</i></Table.Cell>
                                <Table.Cell singleLine><b>200 kr</b></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Graderingsavgift Vuxna<br /><i>(inklusive bälte och diplom)</i></Table.Cell>
                                <Table.Cell singleLine><b>300 kr</b></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Ju-jutsupass Vuxna<br /><i>(införskaffas senast vid första gradering)</i></Table.Cell>
                                <Table.Cell singleLine><b>150 kr</b></Table.Cell>
                            </Table.Row>
                        </Table>

                    </Card>
                    <Card>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell colspan={2}><Header as='h3' textAlign="center">Övrigt</Header></Table.HeaderCell>
                            </Table.Header>
                            <Table.Row>
                                <Table.Cell>Systembok</Table.Cell>
                                <Table.Cell singleLine><b>450 kr</b></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Systembok Mon</Table.Cell>
                                <Table.Cell singleLine><b>350 kr</b></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Klubbmärke i tyg</Table.Cell>
                                <Table.Cell singleLine><b>50 kr</b></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Förbundsmärke</Table.Cell>
                                <Table.Cell singleLine><b>50 kr</b></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colspan={2}>Vi kan hjälpa till att beställa Gi (dräkt) för de som önskar. De görs normalt någon månad efter terminsstart. Priset varierar med storlek</Table.Cell>

                            </Table.Row>
                        </Table>

                    </Card>
                </Card.Group>

                <Segment textAlign="left">

                    <Header as='h3'>Vad används pengarna till?</Header>
                    <p style={{ fontSize: "1.33em" }}>
                        <b>Medlemsavgiften</b> ska täcka avgifter till organisationer som Falu JJK är medlem i. Dessa organisationer är Svenska ju-jutsuförbundet och
                        Riksidrottsförbundet (genom Svenska Budo- och Kampsportsförbundet). I medlemsavgiften ingår också en olycksfallsförsäkring.
                    </p>
                    <p style={{ fontSize: "1.33em" }}>

                        <b>Träningsavgiften</b> ska täcka klubbens kostnader för att driva verksamheten, dvs lokalen, utrustning, instruktörsutbildningar mm. Falu ju-jutsuklubb är en ideell förening vilket betyder att alla som arbetar i föreningen gör detta på sin fritid och utan betalning. Den enda förmån som utgår till instruktörer och vissa funktionärer är en reducering av träningsavgiften.
                    </p>
                </Segment>
                <p></p>
            </Container>
      
    );
};

export default AvgifterPage;