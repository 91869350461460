import React from "react";


const Beltimage = (props) => {

    if (props.type === "white")
    {
        return (
            <svg width={70} height={20}><rect x="2" y="2" width="66" height="18" stroke="black" stroke-width="1" fill="white" />
            </svg>
        ); 
    }
    if (props.type === "yellow")
        {
            return (
                <svg width={70} height={20}><rect x="2" y="2" width="66" height="18" stroke="black" stroke-width="1" fill="yellow" />
                </svg>
            ); 
        }
    if (props.type === "orange")
    {
        return (
            <svg width={70} height={20}><rect x="2" y="2" width="66" height="18" stroke="black" stroke-width="1" fill="orange" />
            </svg>
        ); 
    }
    if (props.type === "green")
    {
        return (
            <svg width={70} height={20}><rect x="2" y="2" width="68" height="18" stroke="black" stroke-width="1" fill="green" />
            </svg>
        ); 
    }
    if (props.type === "blue")
    {
                return (
            <svg width={70} height={20}><rect x="2" y="2" width="68" height="18" stroke="black" stroke-width="1" fill="blue" />
            </svg>
        ); 
    }
    if (props.type === "brown")
    {
        return (
            <svg width={70} height={20}><rect x="2" y="2" width="68" height="18" stroke="black" stroke-width="1" fill="brown" />
            </svg>
        ); 
    }
    if (props.type === "1dan")
    {
        return (
            <svg width={70} height={20}><rect x="2" y="2" width="68" height="18" stroke="black" stroke-width="1" fill="black" />
            </svg>
        ); 
    }
    if (props.type === "2dan")
    {
        return (
            <svg width={70} height={20}><rect x="2" y="2" width="68" height="18" stroke="black" stroke-width="1" fill="black" />
            <line x1="10" y1="2" x2="10" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="14" y1="2" x2="14" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
        </svg>
        ); 
    }
    if (props.type === "3dan")
    {
        return (
            <svg width={70} height={20}><rect x="2" y="2" width="68" height="18" stroke="black" stroke-width="1" fill="black" />
            <line x1="10" y1="2" x2="10" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="14" y1="2" x2="14" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="18" y1="2" x2="18" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            </svg>
        ); 
    }
    if (props.type === "4dan")
    {
        return (
            <svg width={70} height={20}><rect x="2" y="2" width="68" height="18" stroke="black" stroke-width="1" fill="black" />
            <line x1="10" y1="2" x2="10" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="14" y1="2" x2="14" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="18" y1="2" x2="18" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="22" y1="2" x2="22" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            </svg>
        ); 
    }
    if (props.type === "5dan")
    {
        return (
            <svg width={70} height={20}><rect x="2" y="2" width="68" height="18" stroke="black" stroke-width="1" fill="black" />
            <line x1="10" y1="2" x2="10" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="14" y1="2" x2="14" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="18" y1="2" x2="18" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="22" y1="2" x2="22" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
            <line x1="26" y1="2" x2="26" y2="19" style={{ stroke:"yellow", strokeWidth: 2 }} />
        </svg>
        ); 
    }
};

export default Beltimage;