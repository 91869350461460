import React, { useLayoutEffect, useEffect, useState } from "react";
import { Container, Segment, Header, Item } from "semantic-ui-react";

const SchemaPage = () => {
  const [schemagroups, setSchemaGroups] = useState();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/data/schema.json")
      .then((response) => response.json())
      //.then(json => json.map( (x) => { return x.name }))
      .then((data) => {
        setSchemaGroups(data);        
      });
  }, []);

  return (
    
      <Segment style={{ padding: "8em 0em" }} vertical>
        <Header as="h1">{schemagroups && schemagroups.schematitle}</Header>
        <p style={{ fontSize: "1.33em" }}>
          Vårt träningsår följer skolåret, så en ny termin startar i augusti och
          i januari.
        </p>
        <p style={{ fontSize: "1.33em" }}>
          <b>Träningslokal</b>: Kampsportshallen på Lugnet
        </p>

        <p></p>

        <Container textAlign="left">
          <Item.Group divided>
            {schemagroups && schemagroups.schemagroups &&
              schemagroups.schemagroups.map((group) => (
                <Item key={group.key}>
                  <Item.Image
                    size="tiny"
                    src={process.env.PUBLIC_URL + "/" + group.imagepath}
                  />
                  <Item.Content>
                    <Item.Header>
                      <p style={{ fontSize: "1.33em" }}>{group.groupname}</p>
                    </Item.Header>
                    <Item.Meta>{group.groupdescription}</Item.Meta>
                    <Item.Description>
                    <p style={{ fontSize: "1.33em" }} >
                      {group.trainingtimes &&
                        group.trainingtimes.map((time) => (
                          <span key={time.childkey}>
                            {time.trainingtimename}<br/>
                            </span>
                        ))}
                        </p>
                    </Item.Description>
                  </Item.Content>
                </Item>
              ))}
          </Item.Group>
        </Container>

      </Segment>
    
  );
};

export default SchemaPage;
