import React, { useLayoutEffect } from "react";

import { Container, Segment, Header, Item, Image, Divider } from "semantic-ui-react";

const GrupperPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>
      <Segment
        placeholder
        style={{
          height: "40em",
          backgroundImage: "url(" + process.env.PUBLIC_URL + "/bakåtfall.jpg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Header
          as="h1"
          content="Våra grupper"
          inverted
          style={{
            fontSize: "4em",
            fontWeight: "normal",
            marginBottom: 0,
            marginTop: "3em",
          }}
        />
      </Segment>

      <Segment style={{ padding: "8em 0em" }} vertical>
        <Container >
          <Item.Group divided>
            <Item>
              <Header as="h1">Barn 7 - 14 år</Header>
            </Item>
            <Item>
              <Item.Image
                src={process.env.PUBLIC_URL + "/knatte4.jpg"}
                size="small"
              ></Item.Image>
              <Item.Content>
                <Item.Header textAlign="left">Knattar 7-9 år</Item.Header>
                <br />

                <Item.Description>
                  <p style={{ fontSize: "1.33em" }}>
                    För att få träna i knattegruppen ska du ha fyllt 7 år. Fokus
                    på barnens träning är motorik, rörlighet och balans, men
                    också samarbete samt budosportens grunder det vill säga
                    respekt och ödmjukhet för varandra.
                    <br />
                    Barnen får även prova på och lära sig enkla tekniker som
                    lägger grunden för att sedan gå vidare till monsystemet.
                    <br />
                    Efter varje termin, i samband med klubbens graderingar får man en röd
                    markering på sitt bälte i knattesystemet.
                  </p>
                </Item.Description>
              </Item.Content>
            </Item>
            <Item>
              <Item.Image
                src={process.env.PUBLIC_URL + "/mon2.jpg"}
                size="small"
              ></Item.Image>
              <Item.Content>
                <Item.Header textAlign="left">
                  Ungdomar 9-14 år (Mon)
                </Item.Header>
                <br />

                <Item.Description>
                  <p style={{ fontSize: "1.33em" }}>
                    Ungdomarna tränar enligt Svenska Ju-jutsuförbundets
                    Mon-system, vilket är anpassat för barn och ungdomar.
                    Träningen innehåller även mycket lek och tränar styrka,
                    rörlighet och balans. I Mon-systemet finns möjlighet att
                    gradera till olika färger på bältet, efter att man har
                    uppnått tillräcklig träningsmängd och kunskapsnivå
                  </p>
                </Item.Description>
              </Item.Content>
            </Item>

            <Item>
              <Header as="h1">Vuxna från 14 år</Header>
            </Item>
            <Item>
              <Item.Image
                src={process.env.PUBLIC_URL + "/försöktillkravattgrepp2.jpg"}
                size="small"
              ></Item.Image>
              <Item.Content>
                <Item.Header textAlign="left">
                  Nybörjare / Grund (Kyu)
                </Item.Header>
                <Item.Description>
                  <p style={{ fontSize: "1.33em" }}>
                    Här tränar alla nybörjare och de som har uppnått gul och
                    orange färg på bältet. Orange färg når de flesta efter 1 - 2
                    års träning. Här övas mycket grunder, vilket är en
                    förutsättning för att kunna träna mer avancerade tekniker.
                    Ju-jutsu är en mycket bred kampsport och innehåller t.ex.
                    slag och sparkar, kast och nedläggningar, markbrottning,
                    fasthållningar och gripatekniker.
                  </p>
                </Item.Description>
              </Item.Content>
            </Item>
            <Item>
              <Item.Image
                src={process.env.PUBLIC_URL + "/påk2.jpg"}
                size="small"
              ></Item.Image>
              <Item.Content>
                <Item.Header textAlign="left">
                  Fortsättning / Avancerade (Kyu och Dan)
                </Item.Header>

                <Item.Description>
                  <p style={{ fontSize: "1.33em" }}>
                    Här tränar de som har grönt bälte och uppåt. Här tränas mer avancerade tekniker och träningen fördjupas mer och mer
                  </p>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
          <Divider></Divider>
          <Image
            centered
            size="large"
            src={process.env.PUBLIC_URL + "/bälten_close.jpg"}
          />

        </Container>
      </Segment>
    </>
  );
};

export default GrupperPage;
